import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

import { asyncFilter } from '../../../../utils/promises';
import { getAllIntegratedApps } from '../../../services/applicationState';
import { isMyWishlist } from '../../../services/myWishlistIntegration';
import { APP_TOKEN } from '../../../constants';
import { IntegrationApplicationWithWidgetId, WidgetPluginPointer } from '../../types';
import { getInstalledApplications } from '../services/members-area-page';
import { getDefinitionsWithWidgetId } from '../services/widget-plugins';

const isVerticalInstalled = (editorSDK: EditorSDK) => (appDefinitionId: string) => {
  return editorSDK.application.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
};

const isApplicationInstalled = (
  application: IntegrationApplicationWithWidgetId,
  installedApplications: WidgetPluginPointer[],
) => {
  return installedApplications.some(({ appDefinitionId, widgetId }) => {
    return application.appDefinitionId === appDefinitionId && application.widgetId === widgetId;
  });
};

export const getRegisteredApps = async (editorSDK: EditorSDK) => {
  const allIntegratedAppsMap = getAllIntegratedApps();
  const installedVerticalIds: string[] = await asyncFilter(
    Object.keys(allIntegratedAppsMap),
    isVerticalInstalled(editorSDK),
  );
  const installedApplications = await getInstalledApplications(editorSDK);

  return installedVerticalIds.reduce((integrationApplicationMap, appDefId) => {
    const definitions = getDefinitionsWithWidgetId(allIntegratedAppsMap[appDefId] as IntegrationApplication[]);

    return definitions.length > 0
      ? {
          ...integrationApplicationMap,
          [appDefId]: definitions.filter((definition) => {
            return !isMyWishlist(definition) && !isApplicationInstalled(definition, installedApplications);
          }),
        }
      : integrationApplicationMap;
  }, {} as IntegrationApplicationMap);
};
