import { AppActionClicked as AppActionClickedEvent, EventType } from '@wix/platform-editor-sdk';

import { openRemovePagePanel, openUninstallPanel } from '../../../wrappers/platformPanels';
import { openGeneralSettingsPanel, openMemberPrivacySettingsDashboard } from '../../../wrappers/panels';
import { MembersAreaEvent, MembersAreaOnEventFn } from '../../types';
import { navigateToMembersAreaPage } from '../services/navigation';
import { handleAppManagerEvent } from './apps-manager';
import { handleManagePages } from './manage-pages';

export const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const { eventType, eventPayload } = event;

  switch (eventType) {
    // Platform Events
    case EventType.appActionClicked:
      return handleAppManagerEvent(editorSDK, event as AppActionClickedEvent);
    // Members Area Events
    case MembersAreaEvent.CreateBlankPage:
      return handleManagePages(editorSDK, eventPayload);
    case MembersAreaEvent.RemovePage:
      return openRemovePagePanel(editorSDK, eventPayload.pageRef);
    case MembersAreaEvent.Uninstall:
      return openUninstallPanel(editorSDK);
    case MembersAreaEvent.GeneralSettings:
      await navigateToMembersAreaPage(editorSDK);
      return openGeneralSettingsPanel({ editorSDK, eventPayload });
    case MembersAreaEvent.MemberPrivacyDashboard:
      return openMemberPrivacySettingsDashboard(editorSDK);
    default:
      throw new Error(`[MAV2] OnEvent.${eventType} handler is not implemented`);
  }
};
