import { WIX_BLOG, WIX_CHALLENGES, WIX_EVENTS, WIX_FORUM } from '@wix/app-definition-ids';
import { IntegrationApplication } from '@wix/members-area-integration-kit';

enum PageId {
  About = 'about',
  FollowingFollowers = 'following_followers',
  MyRewards = 'My Rewards',
  ForumPosts = 'member-posts-page',
  ForumComments = 'member-comments-page',
  Events = 'events_members_page',
  BlogComments = 'member-comments-page',
  BlogLikes = 'member-likes-page',
  BlogPosts = 'member-posts-page',
  BlogDrafts = 'member-drafts-page',
  MyPosts = 'My Posts',
  MyChallenges = 'My Challenges',
  MyPrograms = 'My Programs',
}

const getWidgetPluginKey = (appDefinitionId: string, pageId: string) => `${appDefinitionId}_${pageId}`;

const WidgetPluginIdMapKey = {
  About: getWidgetPluginKey('14dbef06-cc42-5583-32a7-3abd44da4908', PageId.About),
  MyRewards: getWidgetPluginKey('16ed1ac6-01cb-4fb6-a59e-c215cce8fdf6', PageId.MyRewards),
  ForumPosts: getWidgetPluginKey(WIX_FORUM, PageId.ForumPosts),
  ForumComments: getWidgetPluginKey(WIX_FORUM, PageId.ForumComments),
  Events: getWidgetPluginKey(WIX_EVENTS, PageId.Events),
  FollowingFollowers: getWidgetPluginKey('14ebe801-d78a-daa9-c9e5-0286a891e46f', PageId.FollowingFollowers),
  BlogComments: getWidgetPluginKey(WIX_BLOG, PageId.BlogComments),
  BlogLikes: getWidgetPluginKey(WIX_BLOG, PageId.BlogLikes),
  BlogPosts: getWidgetPluginKey(WIX_BLOG, PageId.BlogPosts),
  BlogDrafts: getWidgetPluginKey(WIX_BLOG, PageId.BlogDrafts),
  MyPosts: getWidgetPluginKey(WIX_BLOG, PageId.MyPosts),
  MyChallenges: getWidgetPluginKey(WIX_CHALLENGES, PageId.MyChallenges),
  MyPrograms: getWidgetPluginKey(WIX_CHALLENGES, PageId.MyPrograms),
};

enum WidgetPluginId {
  About = '14dbefb9-3b7b-c4e9-53e8-766defd30587',
  MyRewards = 'c85a2896-9f7c-4cf1-9bf8-95852cc5219a',
  ForumPosts = '14f0266c-e0a3-afc6-d07c-5be8e20949cd',
  ForumComments = '14f51b00-3ada-272e-b6db-34d5da9dd623',
  Events = '405eb115-a694-4e2b-abaa-e4762808bb93',
  FollowingFollowers = '14ebe825-b273-0d5f-0fd1-e6293322b83b',
  BlogComments = '14f260f9-c2eb-50e8-9b3c-4d21861fe58f',
  BlogLikes = '14f26109-514f-f9a8-9b3c-4d21861fe58f',
  BlogPosts = '14f26118-b65b-b1c1-b6db-34d5da9dd623',
  BlogDrafts = '14f260e4-ea13-f861-b0ba-4577df99b961',
  MyPosts = 'e5a2773b-0e6b-4cbb-a012-3b4a69e92046',
  MyChallenges = '384628b7-c716-4410-8fc5-4e2bd5aad178',
  MyPrograms = 'c88902a1-7fbf-4ff1-b19a-af39c48bc740',
}

const widgetPluginIdMap: { [key in string]: WidgetPluginId } = {
  [WidgetPluginIdMapKey.About]: WidgetPluginId.About,
  [WidgetPluginIdMapKey.MyRewards]: WidgetPluginId.MyRewards,
  [WidgetPluginIdMapKey.ForumPosts]: WidgetPluginId.ForumPosts,
  [WidgetPluginIdMapKey.ForumComments]: WidgetPluginId.ForumComments,
  [WidgetPluginIdMapKey.Events]: WidgetPluginId.Events,
  [WidgetPluginIdMapKey.FollowingFollowers]: WidgetPluginId.FollowingFollowers,
  [WidgetPluginIdMapKey.BlogComments]: WidgetPluginId.BlogComments,
  [WidgetPluginIdMapKey.BlogLikes]: WidgetPluginId.BlogLikes,
  [WidgetPluginIdMapKey.BlogPosts]: WidgetPluginId.BlogPosts,
  [WidgetPluginIdMapKey.BlogDrafts]: WidgetPluginId.BlogDrafts,
  [WidgetPluginIdMapKey.MyPosts]: WidgetPluginId.MyPosts,
  [WidgetPluginIdMapKey.MyChallenges]: WidgetPluginId.MyChallenges,
  [WidgetPluginIdMapKey.MyPrograms]: WidgetPluginId.MyPrograms,
};

function getWidgetId(appDefinitionId: string, pageId: string) {
  return widgetPluginIdMap[getWidgetPluginKey(appDefinitionId, pageId)];
}

export function isWidgetPluginCreated(appDefinitionId: string, pageId: string) {
  const widgetId = getWidgetId(appDefinitionId, pageId);
  return Boolean(widgetId);
}

// TODO: widgetId should be part of integration application
export const getDefinitionWithWidgetId = (definition: IntegrationApplication) => ({
  ...definition,
  widgetId: getWidgetId(definition.appDefinitionId, definition.pageId),
});

// TODO: widgetId should be part of integration application
export function getDefinitionsWithWidgetId(definitions: IntegrationApplication[]) {
  return definitions
    .filter(({ appDefinitionId, pageId }) => isWidgetPluginCreated(appDefinitionId, pageId))
    .map(getDefinitionWithWidgetId);
}

const widgetIdPageIdMap: { [key in WidgetPluginId]: PageId } = {
  [WidgetPluginId.About]: PageId.About,
  [WidgetPluginId.MyRewards]: PageId.MyRewards,
  [WidgetPluginId.ForumPosts]: PageId.ForumPosts,
  [WidgetPluginId.ForumComments]: PageId.ForumComments,
  [WidgetPluginId.Events]: PageId.Events,
  [WidgetPluginId.FollowingFollowers]: PageId.FollowingFollowers,
  [WidgetPluginId.BlogComments]: PageId.BlogComments,
  [WidgetPluginId.BlogLikes]: PageId.BlogLikes,
  [WidgetPluginId.BlogPosts]: PageId.BlogPosts,
  [WidgetPluginId.BlogDrafts]: PageId.BlogDrafts,
  [WidgetPluginId.MyPosts]: PageId.MyPosts,
  [WidgetPluginId.MyChallenges]: PageId.MyChallenges,
  [WidgetPluginId.MyPrograms]: PageId.MyPrograms,
};

export const getPageIdFromWidgetId = (widgetId: string) => {
  return widgetIdPageIdMap[widgetId];
};
