import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { createBIService } from '../../../../utils/bi';
import { getVerticalsApps } from '../../../services/applicationState';
import { IntegrationApplicationWithWidgetId } from '../../types';
import { isWidgetPluginCreated, getDefinitionsWithWidgetId } from '../services/widget-plugins';
import { addApplicationsToSlots } from '../services/members-area-page';

export const installApps = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  return addApplicationsToSlots(editorSDK, definitions);
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false && isWidgetPluginCreated(app.appDefinitionId, app.pageId);
  });

  if (integrationAppsToInstall.length > 0) {
    const definitions = getDefinitionsWithWidgetId(integrationAppsToInstall);
    await addApplicationsToSlots(editorSDK, definitions);
  }

  // TODO: Set BWP on install

  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
