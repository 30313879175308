import { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { toMonitored } from '../../../utils/monitoring';

import { APP_TOKEN } from '../../constants';
import enforceSequentiality from '../../enforceSequentiality';
import { setIsUninstalling } from '../../services/applicationState';
import { MembersAreaPrivateApi } from '../types';

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => ({
  uninstall: async () => {
    return enforceSequentiality('private-api.uninstall', () =>
      toMonitored('uninstall', async () => {
        setIsUninstalling(true);
        await editorSDK.application.uninstall('', { openConfirmation: false });
        await editorSDK.history.add(APP_TOKEN, { label: 'Deleting Members Area' });
        await editorSDK.editor.save(APP_TOKEN);
        setIsUninstalling(false);
      }),
    );
  },
  getAllRouters: () => {
    throw new Error('[MAV2] PrivateAPI.getAllRouters is not implemented');
  },
  getRouterDataByPage: (pageRef: PageRef) => {
    throw new Error('[MAV2] PrivateAPI.getRouterDataByPage is not implemented');
  },
  updatePageDataInRouter: (pageRef: PageRef, dataFieldToUpdate: string, updatedData: string | object) => {
    throw new Error('[MAV2] PrivateAPI.updatePageDataInRouter is not implemented');
  },
  saveInnerRoute: (newInnerRoute: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
    throw new Error('[MAV2] PrivateAPI.saveInnerRoute is not implemented');
  },
  updatePageData: ({ pageRef, pageData }: { pageRef: PageRef; pageData: PageData }) => {
    throw new Error('[MAV2] PrivateAPI.updatePageData is not implemented');
  },
  savePasswordPermissions: (pageRef: PageRef, privacy: string, plainPassword: string) => {
    throw new Error('[MAV2] PrivateAPI.savePasswordPermissions is not implemented');
  },
  hasSocialPages: () => {
    throw new Error('[MAV2] PrivateAPI.hasSocialPages is not implemented');
  },
  renamePage: (pageName: string, pageRef: PageRef, pageRouterData: { innerRoute: string }) => {
    throw new Error('[MAV2] PrivateAPI.renamePage is not implemented');
  },
  removePage: (pageData: { id: string }) => {
    throw new Error('[MAV2] PrivateAPI.removePage is not implemented');
  },
});
