import { EditorSDK, PageData } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MA_APP_IDS } from '@wix/members-area-integration-kit';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { Page, PagesMap, PagesModificationPayload, PageToModify, PageType } from '../../../../types/general-settings';
import { ReferralInfo } from '../../../../types/bi';
import { getTranslationFunction } from '../../../../i18n';
import { arePagesEqual } from '../../../../utils/pages';
import { APP_TOKEN, MEMBERS_LIST_APP_DEF_ID, MEMBERS_LIST_PAGE_ID } from '../../../constants';
import { getAllPages } from '../../../wrappers/pages';
import { openManagePagesPanel } from '../../../wrappers/panels';
import { WidgetPluginPointer } from '../../types';
import { getPageIdFromWidgetId, getDefinitionsWithWidgetId, getDefinitionWithWidgetId } from './widget-plugins';
import { getPublicApplications, addApplicationsToSlots, removeApplicationsFromSlots } from './members-area-page';

const getPagesFromMembersAreaPage = async (
  editorSDK: EditorSDK,
  widgetPluginPointers: WidgetPluginPointer[],
): Promise<Page[]> => {
  const applications = widgetPluginPointers.map(({ appDefinitionId, widgetId }) => ({
    appDefinitionId,
    pageId: getPageIdFromWidgetId(widgetId),
  }));
  const definitions = await getAppDefinitions({
    editorSDK,
    applications,
    i18next: createInstance(),
  });

  return getDefinitionsWithWidgetId(definitions).map((definition) => ({
    pageType: PageType.WidgetPlugin,
    integrationApplication: definition,
    title: definition.page?.name ?? definition.loginMenuTitle ?? '',
    isInstalled: true,
  }));
};

const getMemberListPage = (pages: PageData[]): Page | null => {
  const memberListPage = pages.find(({ tpaPageId }) => tpaPageId === MEMBERS_LIST_PAGE_ID);

  if (!memberListPage) {
    return null;
  }

  return {
    pageType: PageType.StandAlone,
    integrationApplication: { appDefinitionId: MEMBERS_LIST_APP_DEF_ID, pageId: undefined as any },
    title: memberListPage.title,
    isInstalled: true,
  };
};

const getInstalledPublicPages = async (editorSDK: EditorSDK): Promise<Page[]> => {
  const [publicApplicationsInMembersAreaPage, allInstalledPages] = await Promise.all([
    getPublicApplications(editorSDK),
    getAllPages({ editorSDK }),
  ]);
  const publicPagesInMembersAreaPage = await getPagesFromMembersAreaPage(
    editorSDK,
    publicApplicationsInMembersAreaPage,
  );
  const membersListPage = getMemberListPage(allInstalledPages);

  return membersListPage ? [...publicPagesInMembersAreaPage, membersListPage] : publicPagesInMembersAreaPage;
};

const getPromotionalPublicPages = async (editorSDK: EditorSDK, publicPages: Page[]): Promise<Page[]> => {
  const t = await getTranslationFunction(editorSDK, true);
  const definitions = await getAppDefinitions({
    editorSDK,
    i18next: createInstance(),
    applications: [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.ALL_MEMBERS],
  });

  return definitions.map((definition: IntegrationApplication) => {
    const promotionalPage = publicPages.find(({ integrationApplication }) => {
      return arePagesEqual(integrationApplication, definition);
    });

    if (promotionalPage) {
      return { ...promotionalPage, application: definition };
    }

    const { page } = definition;
    const isMembersListPage = arePagesEqual(definition, MA_APP_IDS.ALL_MEMBERS);

    return {
      pageType: isMembersListPage ? PageType.StandAlone : PageType.WidgetPlugin,
      title: isMembersListPage ? t('GeneralSettings_MembersPageTitle') : page?.name ?? '',
      integrationApplication: isMembersListPage ? definition : getDefinitionWithWidgetId(definition),
      isInstalled: false,
    };
  });
};

export const getGeneralSettingsPanelData = async (editorSDK: EditorSDK) => {
  const installedPublicPages = await getInstalledPublicPages(editorSDK);
  const promotionalPublicPages = await getPromotionalPublicPages(editorSDK, installedPublicPages);
  return { installedPublicPages, promotionalPublicPages };
};

const groupPagesByPageType = (pages: PageToModify[]) => {
  const pagesMap: PagesMap = {
    [PageType.WidgetPlugin]: [],
    [PageType.StandAlone]: [],
    [PageType.Custom]: [],
    [PageType.TPA]: [],
  };

  pages.forEach(({ pageType, integrationApplication }) => {
    if (pageType === PageType.WidgetPlugin) {
      pagesMap[pageType].push(integrationApplication);
    } else {
      pagesMap[pageType].push(integrationApplication);
    }
  });

  return pagesMap;
};

const installPublicPages = async (editorSDK: EditorSDK, pages: PagesMap) => {
  if (pages[PageType.WidgetPlugin].length > 0) {
    await addApplicationsToSlots(editorSDK, pages[PageType.WidgetPlugin]);
  }
};

const removePublicPages = async (editorSDK: EditorSDK, pages: PagesMap) => {
  if (pages[PageType.WidgetPlugin].length > 0) {
    await removeApplicationsFromSlots(editorSDK, pages[PageType.WidgetPlugin]);
  }
};

export const modifyPages = async (
  editorSDK: EditorSDK,
  { appsToInstall, appsToRemove, profilePageType }: PagesModificationPayload,
) => {
  const t = await getTranslationFunction(editorSDK, true);
  const totalSteps = appsToInstall.length + appsToRemove.length + (profilePageType ? 1 : 0);
  let currentStep = 0;

  if (!totalSteps) {
    return;
  }

  await editorSDK.editor.openProgressBar(APP_TOKEN, {
    title: t('GeneralSettings_ProgressModal_Title'),
    totalSteps,
    stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
  });

  try {
    if (appsToInstall.length) {
      const pagesToInstallMap = groupPagesByPageType(appsToInstall);

      await installPublicPages(editorSDK, pagesToInstallMap);
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: (currentStep += appsToInstall.length),
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    if (appsToRemove.length) {
      const pagesToRemoveMap = groupPagesByPageType(appsToRemove);

      await removePublicPages(editorSDK, pagesToRemoveMap);
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: (currentStep += appsToRemove.length),
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    // TODO: Implement BWP feature
    if (profilePageType) {
      // await setProfileType(editorSDK, profilePageType);
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: ++currentStep,
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: false,
    });
  } catch (e) {
    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: true,
    });

    throw e;
  }
};

export const modifyPagesAndOpenManagePages = async (
  editorSDK: EditorSDK,
  options: PagesModificationPayload,
  referralInfo?: ReferralInfo,
) => {
  await modifyPages(editorSDK, options);
  return openManagePagesPanel({ editorSDK, eventPayload: { pageRef: undefined, referralInfo } });
};
